import axios from 'axios';

const api = axios.create({
  baseURL: "https://back-criativa-doations-635d356bed7c.herokuapp.com", //`${process.env.REACT_APP_API_URL}`,   
  // baseURL: "http://localhost:3000",
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer re_G7LKyekX_6aFzS7HoWnH9YM1AE3HR3NHQ' 
  }
});
 

export default api; 